const Menu = [
  {
    label: 'Home',
    pathname: '/',
  },
  {
    label: 'About Us',
    pathname: '/about',
  },
  {
    label: 'Products',
    pathname: '/products',
  },
  {
    label: 'Signup',
    pathname: '/signup',
  },
  // {
  //   label: "Github",
  //   pathname: "https://github.com/alexanmtz/material-sense",
  //   external: true
  // }

];

export default Menu;
